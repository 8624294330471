<template>
  <div class="main">
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div class="object" id="object_one"></div>
          <div class="object" id="object_two"></div>
          <div class="object" id="object_three"></div>
          <div class="object" id="object_four"></div>
          <div class="object" id="object_five"></div>
          <div class="object" id="object_six"></div>
          <div class="object" id="object_seven"></div>
          <div class="object" id="object_eight"></div>
          <div class="object" id="object_big"></div>
        </div>
      </div>
    </div>
    <div class="txt">
      <div class="p">{{ $t('正在验证您的身份，请稍等') }}</div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { dingPCLogin, dingH5Login } from "@/api/user";
import { successCallback } from "@/utils/login.js";
import * as dd from "dingtalk-jsapi";
export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      appid: route.query.appid || "",
      corpId: route.query.corpId || "",
      dingCorpid: route.query.dingCorpid || route.query.corpId || "",
      platform: route.query.platform || 30,
      redirect: decodeURIComponent(route.query.redirect || "/"),
    });

    onMounted(() => {
      fnLogin();
    });

    const fnLogin = () => {
      let dingAjax = state.platform == 30 ? dingPCLogin : dingH5Login;
      dd.ready(() => {
        dd.runtime.permission.requestAuthCode({
          corpId: state.platform == 30 ? state.corpId : state.dingCorpid, //企业id
          onSuccess: (result) => {
            dingAjax({
              site: location.host,
              code: result.code,
              appid: state.platform == 30 ? state.appid : state.dingCorpid,
            }).then((res) => {
              if (res.ret == 0) {
                // if (state.platform == 31) window.DTOpenData.init(state.dingCorpid);
                successCallback(res, state);
                store.dispatch("setPlatform", "dingding");
              }
            });
          },
          onFail: (err) => {
            if (state.platform == 30) {
              alert(JSON.parse(JSON.stringify(err)));
            } else {
              location.href = location.origin + "?corpid=" + state.dingCorpid;
            }
          },
        });
      });
    };

    return {};
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/login.less";
</style>
